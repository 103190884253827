import React, { useState, useEffect , useRef} from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { GetAll_Creators } from "./actions/CreatorsActions";
import { GetRequestedList } from "./actions/BrandActions";
import LandingPage from "./pages/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Routes, BrowserRouter as Router, Route, useParams, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import SignupForm from "./components/SignupForm/SignupForm";
import SignupForm2 from "./components/SignUpForm2/SignupForm2";
import Login from "./components/Login/Login";
import Insta from "./components/instagram/Instagram";
import CampaignDashboard from "./components/CampaignDashboard/CampaignDashboard";
import Talent from "./components/TalentPage/Talent";
import InfluencerProfile from "./components/InfluencerProfile/InfluencerProfile";
import BrandProfile from "./components/BrandProfile/BrandProfile";
import UpdateInfluencer from "./components/UpdateInfluencer/UpdateInfluencer";
import Contact from "./pages/Contact/Contact";
import CampaignView from "./components/CampaignView/CampaignView";
import Brand from "./pages/Brand/Brand";
import Creator from "./pages/Creator/Creator";
import InstagramLogin from "./components/instagram/InstagramLogin";
import Admin from "./components/Admin/Admin";
import CreateCampaign from "./components/CreateCampaign/CreateCampaign";
import InfluxDB from "./components/InfluxDB/InfluxDB";
import baseUrl from "./api/universalApi";
import { auth } from "./firebase-config";

const App = () => {
 
  const dispatch = useDispatch();
  const [creatorss, setCreators] = useState([]);
  const [isBrand, setisBrand] = useState(false);
  const [Requests, setRequests] = useState([]);
  const authData = useSelector((state) => state.auth.authData);
  //const [connectedUsers, setConnectedUsers] = useState([]);
  const [data, setData] = useState(null)

  const [influencerData, setInfluencerData] = useState(null);
 
  const wsRef = useRef(null);
  const location = useLocation
 




  const addRequest = (request)=>{
    setRequests(request);
  }
 

  useEffect(() => {
    (async function fetchCreators() {
      const result = await dispatch(GetAll_Creators()); // Dispatch action and wait for the result
      setCreators(result); // Set the creators if your result contains the data
    })();
    if (authData && authData.user.userType === "Brand") {
      setisBrand(!isBrand);
     

      const fetchRequestedList = async () => {
        const id = authData.user._id;
        try {
          const response = await dispatch(GetRequestedList(id));
          //console.log(response)
          setRequests(response.data);
          // You can use `requestedListData` here or store it in state if needed
        } catch (error) {
          console.error("Error fetching requested list:", error);
        }
      };

      // Fetch the requested list when `authData` is available
      fetchRequestedList();
    }
  }, [dispatch]);
  //const brandData = authData.user;
 
  /// make some condition to define brand is logged in then cart possible
  const handleSubmission = async (campaignData, influencerData,id) => {
    try {
      setData(campaignData);
      setInfluencerData(influencerData)
      console.log(campaignData);
  
      const response = await fetch(`${baseUrl}/campaign/createCampaignCron/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specifying JSON data
        },
        body: JSON.stringify({
          accessToken :influencerData.longLivedAccess,
          campaignName: campaignData.campaignName,
          endDate:campaignData.endDate
        }),
      });
     if(response.ok){
      //fetchInfluencer(campaignData.influencerID)
      console.log("this is influencer ID", campaignData.influencerID);
     }
     
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };


  const CodeExchange = async (Ecode) => {
    console.log("Exchange Code:", Ecode);
    const userId = authData.user._id; // Assuming `authData` is accessible here
    console.log("UserId:", userId);
  
    try {
      // Define API endpoint for code exchange
      const apiEndpoint = `${baseUrl}/api/exchangeCode`; // Replace with your backend endpoint
      // Prepare the request payload
      const payload = {
        userId: userId,
        code: Ecode,
      };
  
      // Make the POST request
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      // Handle the API response
      if (response.ok) {
        const data = await response.json();
        console.log("Access Token Response:", data);
      } else {
        const error = await response.text();
        console.error("API Error:", error);
      }
    } catch (error) {
      console.error("Network or Processing Error:", error);
    }
  };
  
  
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>

     
          <Route path="/" element={<LandingPage CodeExchange={CodeExchange}/>}></Route>
          <Route path="/influxdb" element={<InfluxDB />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/Signup" element={<SignupForm />}></Route>
          <Route path="/Signupform" element={<SignupForm2 />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route
            path="/Talent"
            element={
              <Talent
                creator={creatorss}
                isBrand={isBrand}
                brandData={authData}
                addRequest={addRequest}
              />
            }
          ></Route>
          <Route path="/instagram" element={<Insta />}></Route>
          <Route path="/instalogin" element={<InstagramLogin />}></Route>

          <Route
            path="/CampaignDashboard"
            element={<CampaignDashboard />}
          ></Route>
          
          <Route path="/influencer/:id" element={<InfluencerProfile />}></Route>
          

          <Route
            path="/Brand/:id"
            element={<BrandProfile requestedCreator={Requests} campaignData={data} influencerData={influencerData}/>}
          ></Route>
          <Route path="/Brand/Campaignview" element={<CampaignView />}></Route>

          {authData && (
            <Route
              path="/createcampaign"
              element={
                <CreateCampaign
                  creatorss={creatorss}
                  brandID={authData.user._id}
                  handleSubmission={handleSubmission}
                />
              }
            ></Route>
          )}

          <Route
            path="/influencer/update/:id"
            element={<UpdateInfluencer />}
          ></Route>

          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/creator" element={<Creator />}></Route>
          <Route path="/Brand" element={<Brand />}></Route>
        </Routes>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
