import React from "react";
import styles from "./CampaignPerformance.module.css";
import { useSelector } from "react-redux";

const CampaignPerformance = ({ views }) => {
  const campaignData = useSelector((state) => state.campaigns.campaigns); // Fetch campaigns from Redux state
  console.log(views);
  return (
    <div className={styles.campaignContainer}>
      {/* Campaign Header */}
      <div className={styles.campaignViews}>
        <div>
          <h4>Campaign Performance</h4>
          <p>View your Campaign Details</p>
        </div>
        
      </div>

      <p>
        <strong>Current Campaign Views:</strong> {views.views}
      </p>

      {/* Campaign Details */}
      <div className={styles.CreatorDetailsBox}>
        {campaignData.slice().reverse().map((campaign, index) => (
          <div key={campaign._id || index} className={styles.CreatorDetail}>
            {/* Left Section: Campaign Logo */}
            <div className={styles.CampaignImage}>
              <img
                src={campaign.campaignLogo || "https://via.placeholder.com/150"}
                alt={`${campaign.campaignName} Logo`}
                className={styles.CampaignLogo}
              />
            </div>

            {/* Right Section: Campaign Details */}
            <div className={styles.CampaignDetails}>
              <p className={styles.CampaignName}>
                <strong>{campaign.campaignName}</strong>
              </p>

              <p>
                <strong>Budget:</strong> ${campaign.totalBudget}
              </p>

              <p>
                <a
                  href={campaign.reelLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Reel
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CampaignPerformance;
