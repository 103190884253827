import React, { useEffect, useState } from "react";
import Overview from "./Overview/Overview";
import Analytics from "./Analytics/Analytics";
import Reports from "./Reports/Reports";
import { useDispatch, useSelector } from "react-redux";

import styles from "./CampaignDash.module.css";

const CampaignDashboard = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const campaignData = useSelector((state) => state.campaigns.campaigns);
  const authData = useSelector((state) => state.auth.authData);
  useEffect(() => {
    //const influencer = authData
    if (true) {
    }
  });

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview />;
      case "analytics":
        return <Analytics />;
      case "reports":
        return <Reports />;
      default:
        return <Overview />;
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <header className={styles.dashboardHeader}>
        <h1>
          {campaignData && campaignData.length > 0
            ? `${campaignData[0].campaignName} - ${authData.user.displayname}`
            : "No campaign"}
        </h1>
        <div className={styles.dateDownload}>
          <div className={styles.dateRange}>
            <span>13 June 2023 - 14 July 2023</span>
          </div>
          <button className={styles.downloadButton}>Download</button>
        </div>
      </header>
      <div className={styles.dashboardNav}>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.navItem} ${
              activeTab === "overview" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </button>
          <button
            className={`${styles.navItem} ${
              activeTab === "analytics" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("analytics")}
          >
            Analytics
          </button>
          <button
            className={`${styles.navItem} ${
              activeTab === "reports" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("reports")}
          >
            Reports
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>{renderContent()}</div>
    </div>
  );
};

export default CampaignDashboard;
