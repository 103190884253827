import { 
    GET_CREATORS_REQUEST, 
    GET_CREATORS_SUCCESS, 
    GET_CREATORS_FAILURE 
    
  } from '../actions/BrandActions';
   // defaults to localStorage for web
  
  
  /* eslint-disable no-undef */
  const brandsReducer = (
    state = {
      creators: [],
      loading: false,
      error: null,
    },
    action
  ) => {
    switch (action.type) {
      case GET_CREATORS_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_CREATORS_SUCCESS:
        return {
          ...state,
            creators: action.data,
            loading: true, 
         
          // Store the creators array in the state
        };
  
      case GET_CREATORS_FAILURE:
        return {
          ...state,
          creators: action.data,
          loading: false,
          error: action.payload, // Store the error message
        };
  
      default:
        return state;
    }
  };
  
  export default  brandsReducer;
  