import React, { useState } from "react";
import styles from "./Overview.module.css";
import CustomLineChart from "./LineChart";
import CampaignPerformance from "./CampaignPerformance";
import { useSelector } from "react-redux";

const Overview = ({ influencerData }) => {
  const [idx, setIdx] = useState(0);
  const [views, setViews] = useState({});
 // console.log("inthe overview", influencerData);

  const campaignData = useSelector((state) => state.campaigns.campaigns[idx]);
 // console.log("i ma ", campaignData);
 

  const handleViews = (views, idx) => {
    setIdx(idx);
    setViews(views);
  };
  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Spent till now</p>
            <h3 className={styles.redText}>
              {campaignData && campaignData.earnedAmount
                ? `₹${campaignData.earnedAmount}`
                : campaignData
                ? "₹0"
                : "₹0.00"}
            </h3>

            <p className={styles.subText}>
              out of budget{" "}
              <p className={styles.redText}>
                {campaignData && campaignData.totalBudget
                  ? `₹${campaignData.totalBudget}`
                  : campaignData
                  ? "₹0"
                  : "₹0.00"}
              </p>
            </p>
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average Engagement Rate</p>
            <h3 className={styles.blackText}>
              {campaignData && campaignData.ER
                ? `${campaignData.ER}%`
                : campaignData
                ? "0.00%"
                : "0.00%"}
            </h3>
            <p className={styles.subText}>70% improvement from previous</p>
          </div>
          <div className={styles.icon}>🥰</div>
        </div>


        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Total Video Views (exclude replays)</p>
            <h3 className={styles.blackText}>
              {campaignData && campaignData.currentReelViews
                ? campaignData.currentReelViews
                : campaignData
                ? "0"
                : "0"}
            </h3>
            <p className={styles.subText}>
              actual views{" "}
              {campaignData
                ? campaignData.currentReelViews
                  ? campaignData.currentReelViews
                  : "0"
                : "0"}
            </p>
          </div>
          <div className={styles.icon}>👀</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average CPV</p>
            <h3 className={styles.blackText}>
              {campaignData
                ? campaignData.ACPV
                  ? campaignData.ACPV
                  : "0"
                : "0.00"}
            </h3>
            <p className={styles.subText}>directly related to current views</p>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>

      </div>
      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>
          {
            <CustomLineChart
              campaignData={campaignData}
              handleViews={handleViews}
            />
          }
        </div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance influencerData={influencerData} views={views} />
        </div>
      </div>
    </>
  );
};

export default Overview;
