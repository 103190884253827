import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../../api/universalApi";

const AddNumbers = () => {
  const [num1, setNum1] = useState(""); // State for first number
  const [num2, setNum2] = useState(""); // State for second number
  const [result, setResult] = useState(null); // State to store the result
  const [error, setError] = useState(null); // State to handle errors
  const [loading, setLoading] = useState(false); // State to show loading state

  const handleAddNumbers = async () => {
    setLoading(true);
    setError(null);
    try {
      // const cloudFunctionUrl = 'https://addnumbers-27064908213.us-central1.run.app';

      // Prepare the payload
      const data = {
        num1: Number(num1), // Ensure the input is converted to a number
        num2: Number(num2),
      };
      // console.log(data);

      // Make the POST request
      const response = await fetch(`${baseUrl}/admin/addNumbers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specifying JSON data
        },
        body: JSON.stringify({
          num1: data.num1,
          num2: data.num2,
        }),
      });

      // Parse the response as JSON
      if (response.ok) {
        const result = await response.json(); // Read and parse the response body
        console.log(result); // Should log the API response { success: true, sum: sumValue }
        setResult(result.sum)
      } else {
        console.error("Error:", response.statusText); // Handle non-200 responses
      }
    } catch (err) {
      // Handle errors
      //  setError(err.response?.data?.message || 'An error occurred while calling the Cloud Function.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h1>Cloud Function: Add Numbers</h1>

      {/* Input for the first number */}
      <div className="form-group mt-3">
        <label htmlFor="num1">First Number</label>
        <input
          type="number"
          className="form-control"
          id="num1"
          value={num1}
          onChange={(e) => setNum1(e.target.value)}
          placeholder="Enter the first number"
        />
      </div>

      {/* Input for the second number */}
      <div className="form-group mt-3">
        <label htmlFor="num2">Second Number</label>
        <input
          type="number"
          className="form-control"
          id="num2"
          value={num2}
          onChange={(e) => setNum2(e.target.value)}
          placeholder="Enter the second number"
        />
      </div>

      {/* Submit Button */}
      <button
        className="btn btn-primary mt-3"
        onClick={handleAddNumbers}
        disabled={loading}
      >
        {loading ? "Calculating..." : "Add Numbers"}
      </button>

      {/* Show Result */}
      {result !== null && (
        <div className="alert alert-success mt-3">
          The result is: <strong>{result}</strong>
        </div>
      )}

      {/* Show Error */}
      {error && (
        <div className="alert alert-danger mt-3">
          Error: <strong>{error}</strong>
        </div>
      )}
    </div>
  );
};

export default AddNumbers;
