import React, { useState } from "react";
import styles from "./Overview.module.css";
import CustomLineChart from "./LineChart";
import CampaignPerformance from "./CampaignPerformance";
import { useSelector } from "react-redux";

const Overview = () => {
  const [idx, setIdx] = useState(0);
const [views, setViews] = useState({});
const campaignDataArray = useSelector((state) => state.campaigns.campaigns);
const campaignData = campaignDataArray && campaignDataArray.length > 0 ? campaignDataArray[idx] : null;

const handleViews = (views, idx) => {
  setIdx(idx);
  setViews(views);
};

const calculateDays = (startDate, endDate) => {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  const remainingDays = Math.max(
    0,
    Math.ceil((end - currentDate) / (1000 * 60 * 60 * 24))
  );

  return { totalDays, remainingDays };
};

let totalDays = 0;
let remainingDays = 0;

// Check if campaignData is valid before calculating days
if (campaignData) {
  const result = calculateDays(campaignData.startDate, campaignData.endDate);
  totalDays = result.totalDays;
  remainingDays = result.remainingDays;
}
  return (
    <>
      <div className={styles.overviewContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Earned till now</p>
            <h3 className={styles.redText}>
              {campaignData && campaignData.earnedAmount
                ? `₹${campaignData.earnedAmount}`
                : campaignData
                ? "₹0"
                : "₹0.00"}
            </h3>

            <p className={styles.subText}>
              out of budget{" "}
              <p className={styles.redText}>
                {campaignData && campaignData.totalBudget
                  ? `₹${campaignData.totalBudget}`
                  : campaignData
                  ? "₹0"
                  : "₹0.00"}
              </p>
            </p>
          </div>
          <div className={styles.icon}>💵</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Total Video Views (exclude replays)</p>
            <h3 className={styles.blackText}>
              {campaignData && campaignData.currentReelViews
                ? campaignData.currentReelViews
                : campaignData
                ? "0"
                : "0"}
            </h3>
            <p className={styles.subText}>
              actual views{" "}
              {campaignData
                ? campaignData.currentReelViews
                  ? campaignData.currentReelViews
                  : "0"
                : "0"}
            </p>
          </div>
          <div className={styles.icon}>👀</div>
        </div>

        <div className={styles.card}>
      <div className={styles.cardContent}>
        <p>Remaining</p>
        <h3 className={styles.blackText}>
          {remainingDays} {remainingDays === 1 ? "Day" : "Days"}
        </h3>
        <p className={styles.subText}>
          out of {totalDays} {totalDays === 1 ? "day" : "days"} of the campaign
        </p>
      </div>
      <div className={styles.icon}>🗓️</div>
    </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <p>Average CPV</p>
            <h3 className={styles.blackText}>
              {campaignData
                ? campaignData.ACPV
                  ? campaignData.ACPV
                  : "0"
                : "0.00"}
            </h3>
            <p className={styles.subText}>directly related to current views</p>
          </div>
          <div className={styles.icon}>🔂</div>
        </div>
      </div>
      <div className={styles.ChartNPerform}>
        <div className={styles.chartContainer}>
          {<CustomLineChart handleViews={handleViews} />}
        </div>
        <div className={styles.performanceContainer}>
          <CampaignPerformance />
        </div>
      </div>
    </>
  );
};

export default Overview;
