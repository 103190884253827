/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./BrandLogin.module.css";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  BrandWithGoogle,
  BrandWithPass,
 
} from "../../../actions/AuthActions.js";
import { GetRequestedList} from '../../../actions/BrandActions.js'
import { auth, facebook } from "../../../firebase-config";
import { signInWithPopup } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import google from "../image/google.png";
import facebook1 from "../image/facebook.png";

const BrandLogin = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    isVerified: false,
  });
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  /// google login case -solved  /////////////
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
      try {
        const { data } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );
       
        //console.log(data);
        if (data.verified_email) {
          // Dispatching data to login
          dispatch(BrandWithGoogle(data.email, navigate));
        //  window.location.reload();
        } else {
          console.log("Something wwrong login with Google or Facebook");
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
    ux_mode: "popup",
  });

  const loginFacebook = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);

      const emailface = result.user.email;

      dispatch(BrandWithGoogle(emailface, navigate));
      alert("LoggedIn Successful !!!");
    } catch (e) {
      console.log(`login error ${e}`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.email) {
      alert("Please enter your email");
    } else if (!formData.password) {
      alert("Enter password");
    } else {
      if (
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
      ) {
        //  ///////
        try {
          const abc = dispatch(BrandWithPass(formData, navigate));
          //console.log(abc);
          
        } catch (error) {
          console.log(error);
          toast.error("Logged In unsuccessful!!", {
            duration: 3000,
          });
        }
      } else {
        alert("Invalid Email. Please try again.");
      }
    }
  };

  return (
    <div className={styles.LoginformContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.heading}>Brand Login</h2>
        <p className={styles.description}>
          We will require your email ID to further proceed and get in touch with
          our team
        </p>

        <input
          className={styles.inputField}
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="    Enter your Email ID"
        />
        <input
          className={styles.inputField}
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="   Enter Password"
        />
        <button type="submit" className={styles.continueButton}>
          Continue
        </button>
        <Toaster />

        <div className={styles.orText}>
          <div>OR</div>
        </div>
        <div className={styles.buttonContainer}>
          <img
            src={google}
            type="submit"
            onClick={() => login()}
            alt="Google logo 24px"
          />
          <img
            src={facebook1}
            type="submit"
            onClick={() => loginFacebook(facebook)}
            alt="Facebook logo 24px"
          />
        </div>
        <div className={styles.NavContainer}>
          <Link to="/signup" className={styles.NavSignup}>
            <p>Signup</p>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default BrandLogin;
