import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";

import store from "./store/ReduxStore";
import initFacebookSDK from "./initFacebookSDK";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";

import { Tooltip, Toast, Popover } from "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
  });
});


initFacebookSDK().then(() => {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <>
      <GoogleOAuthProvider clientId="319566936145-6au8j94l2gvlqh4amtqj9afl1r6ava2q.apps.googleusercontent.com">
        <Provider store={store}>
         
            <App />
          
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
});
