import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../../../api/universalApi";
import { useDispatch, useSelector } from "react-redux";
import { saveCampaignData } from "../../../actions/BrandActions";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "bootstrap/dist/css/bootstrap.min.css";

const CustomLineChart = ({ handleViews }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedGraph, setSelectedGraph] = useState("all"); // Default to "all" graphs
  const campaignData = useSelector((state) => state.campaigns.campaigns);  //this is an array

  const handleSelectionChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (newValue) {
      handleRefresh(newValue);
    }
  };

  const handleGraphChange = (event) => {
    setSelectedGraph(event.target.value);
  };

  const handleRefresh = async (value) => {
    try {
      if (value) {
        const response = await axios.post(`${baseUrl}/campaign/campaign-refresh`, {
          campaignName: value,
        });

        const fetchedData = response.data.data;
        const campaignData = response.data.campaignData;

        const matchedCampaignIndex = campaignData.findIndex(
          (campaign) => campaign.campaignName === value
        );

        setData(fetchedData);
        dispatch(saveCampaignData(campaignData));

        handleViews(fetchedData, matchedCampaignIndex);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formattedData = data
    .map((entry) => {
      const date = new Date(entry.timestamp);
      if (isNaN(date)) {
        console.warn(`Invalid timestamp detected: ${entry.timestamp}`);
        return null;
      }
      const time = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      return {
        name: time,
        dataset1: entry.likes,
        dataset2: entry.views,
        dataset3: entry.shares,
        timestamp: entry.timestamp,
      };
    })
    .filter((entry) => entry !== null);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, dataset1, dataset2, dataset3 } = payload[0].payload;
      return (
        <div className="card p-2 bg-light border-0 shadow">
          <p className="fw-bold mb-1">{`Time: ${name}`}</p>
          <p className="mb-1">{`Date: ${new Date(
            payload[0].payload.timestamp
          ).toLocaleDateString()}`}</p>
          <p className="text-primary mb-1">{`Likes: ${dataset1}`}</p>
          <p className="text-info mb-0">{`Views: ${dataset2}`}</p>
          <p className="text-success mb-0">{`Shares: ${dataset3}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <div className="my-4">
        <div>
          Campaign Performance{" "}
          <div
            className="btn"
            onClick={() => selectedValue && handleRefresh(selectedValue)}
          >
            🔃
          </div>{" "}
        </div>

        <div className="d-flex gap-2 mt-3">
          {/* Campaign Selection Dropdown */}
          <select value={selectedValue} onChange={handleSelectionChange}>
            <option value="">Select Campaign</option>
            {campaignData &&
              campaignData.map((campaign) => (
                <option key={campaign._id} value={campaign.campaignName}>
                  {campaign.campaignName}
                </option>
              ))}
          </select>

          {/* Graph Selection Dropdown */}
          <select value={selectedGraph} onChange={handleGraphChange}>
            <option value="all">All</option>
            <option value="likes">Likes</option>
            <option value="views">Views</option>
            <option value="shares">Shares</option>
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          
          {/* Conditionally Render Areas Based on Selected Graph */}
          {(selectedGraph === "all" || selectedGraph === "likes") && (
            <Area
              type="monotone"
              dataKey="dataset1"
              name="Likes"
              stroke="#EA0063"
              fill="#EA0063"
              opacity={0.3}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "views") && (
            <Area
              type="monotone"
              dataKey="dataset2"
              name="Views"
              stroke="#8884d8"
              fill="#8884d8"
              opacity={0.3}
            />
          )}
          {(selectedGraph === "all" || selectedGraph === "shares") && (
            <Area
              type="monotone"
              dataKey="dataset3"
              name="Shares"
              stroke="#00FF00"
              fill="#00FF00"
              opacity={0.3}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
