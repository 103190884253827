import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import {thunk} from "redux-thunk";
import reducers  from "../reducers/index";
import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "inggage";

// Encrypt data
function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
}

// Decrypt data
function decryptData(data) {
  const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}


function saveToLocalStorage(store) {
  try {
    const serializedStore = JSON.stringify(store);
    const encryptedStore = encryptData(serializedStore);
    window.localStorage.setItem("store", encryptedStore);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const encryptedStore = window.localStorage.getItem("store");
    if (encryptedStore === null) return undefined;
    return JSON.parse(decryptData(encryptedStore));
  } catch (e) {
    console.log(e);
    return undefined;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadFromLocalStorage();

const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;


// import { configureStore } from '@reduxjs/toolkit'
// import reducers from '../reducers/index';


//  const store = configureStore({
//   reducer: {
//     auth:reducers.auth,
//     creators:reducers.creators
//   },
// })

// export default store;