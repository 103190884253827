import { combineReducers } from "redux";

import authReducer from "./AuthReducer";
import brandsReducer from "./BrandsReducer";
import campaignReducer from "./campaignReducer";
//import chatReducer from "./ChatUserReducer";

 const reducers = combineReducers({
   auth: authReducer,
   creators: brandsReducer,
   campaigns:campaignReducer
    // chatReducer
    })

    export default reducers;