import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const InstagramLogin = () => {
  const authData = useSelector((state) => state.auth.authData);

  // Instagram OAuth URL parameters
  const clientId = "840475894830454"; // Replace with your Instagram App's client ID
  const redirectUri = "https://www.inggage.com/"; // Use local redirect URI for development

  const scope =
    "instagram_business_basic,instagram_business_content_publish,instagram_business_manage_messages,instagram_business_manage_comments"; // Permissions required
  const state = Math.random().toString(36).substring(2); // Random string

  // Construct Instagram Login URL
  const instagramLoginUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;

  const handleLogin = () => {
    if (authData && authData.token) {
      // Save the state to local storage for validation after redirect
      localStorage.setItem("oauth_state", state);
      // Open Instagram login URL in a new tab
      window.open(instagramLoginUrl, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <button className="btn btn-primary" onClick={handleLogin}>
        Login with Instagram
      </button>
    </div>
  );
};

export default InstagramLogin;
