import {
    GET_CAMPAIGN_REQUEST,
    SAVE_CAMPAIGN_REQUEST,
    GET_CAMPAIGN_FAILURE
  } from '../actions/BrandActions';
  
  const campaignReducer = (
    state = {
      campaigns: [],
      loading: false,
      error: null,
    },
    action
  ) => {
    switch (action.type) {
      case GET_CAMPAIGN_REQUEST:
        return {
          ...state,
          loading: true,
          error: null, // Clear previous errors when starting a new request
        };
  
      case SAVE_CAMPAIGN_REQUEST:
        return {
          ...state,
          campaigns: action.payload,
          error: null, // Clear previous errors when starting save request
        };
  
      case GET_CAMPAIGN_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload, // Store the error message
        };
  
      default:
        return state;
    }
  };
  
  export default campaignReducer;
  