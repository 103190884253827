import React,{useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import styles from "./LandingPage.module.css";
import  { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";



//  landing page sections
import About from "./about-section/About";
import Reporting from "./reporting-section/Reporting";
import Campaign from "./campaign-section/Campaign";
import Testimonials from "./testinomials/Testimonials";
import Brands from "./brands-section/Brands";
import Stats from "./stats-demo/Stats";
import logo2 from "../../assets/logo2.png";
import imgPlus from "../../assets/imgPlus.png";
import Newsletter from './Newsletter/Newsletter'
//import Vector from "../../assets/Vector.png";

const LandingPage = ({CodeExchange}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const authData = useSelector((state) => state.auth.authData);

  
  const handleJoinClick = () => {
    navigate("/signup");
    // toast.success("You Clicked Waitlist!",{
    //   duration:2000,
    // });
  };

    // Extract the code from the query string after redirect
    //const useQuery = () => new URLSearchParams(useLocation().search);

    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      const Ecode = query.get("code");
      setCode(Ecode);
      const returnedState = query.get("state");
      const storedState = localStorage.getItem("oauth_state");
  
      // Validate the state parameter
      if (Ecode && returnedState === storedState && authData.token) {
        console.log("Authorization Code:", Ecode);
        CodeExchange(Ecode);

        // You can now send the code to your backend for token exchange
      } else if (Ecode && returnedState !== storedState) {
        console.error("State mismatch. Possible CSRF attack.");
      }
    }, []);

  return (
    <>
      <Toaster />
      {/* Section 1 - Hero Section */}
      <div className={styles.landingcontainer}>
        <div className={styles.landingText}>
          <h1>
            India’s first pay as you go creator platform.
            <br />
          </h1>

          <h1>
            <img className={styles.inggageImg} src={logo2} alt="inggage" /> with
            <img src={imgPlus} className={styles.inggageCreator} alt="inggage"/>
            creators and brands
          </h1>
        </div>
        <div className={styles.landingPara}>
          Our AI-powered platform optimizes your campaigns in real time, using
          real-time analytics and payment views to ensure you only pay for
          measurable results.
        </div>
        <div className={styles.landingButtons}>
          <button className={styles.joinWaitlist} onClick={handleJoinClick}>
            Join the Waitlist
          </button>

          <button
            className={styles.contactUs}
            onClick={() =>
              (window.location.href =
                "mailto:suryansu@inggage.com?subject=My%20message%20to%20Inggage")
            }
          >
            Contact Us
          </button>
        </div>
      </div>

      {/* Stats chart section*/}
      <Stats />

      {/* Brands Animation Section */}
      <Brands />

      {/* About Section */}
      <About />

      {/* Advanced reporting Section */}
      <Reporting />

      {/* Campaign Poster */}
      <Campaign />

      {/* Testimonials */}
      <Testimonials />

      {/* Newsletter-section */}
     

      <Newsletter/>
    </>
  );
};

export default LandingPage;
