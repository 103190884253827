/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./CreateCampaign.module.css";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

const CreateCampaign = ({ creatorss, brandID, handleSubmission }) => {
  const [loading, setLoading] = useState(false); // State to show loading state
  const [formData, setFormData] = useState({
    campaignName: "",
    campaignLogo: null,
    campaignDescription: "",
    campaignGoal: "",
    customGoal: "",
    platform: "Instagram",
    startDate: "",
    endDate: "",
    influencerID: "",
    ER: "",
    brandID: brandID,
    influencerName: "",
    accessToken: "",
    instagramID: "",
    totalBudget: "",
    reelLink: "",
    deliverables: "reel", // Default value set here
  });

  const [filteredInfluencers, setFilteredInfluencers] = useState([]);
  const [selectedInfluencersDetails, setSelectedInfluencersDetails] =
    useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [influencerData, setInfluencerData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoalChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, campaignGoal: value, customGoal: "" });
  };

  const handleSelectInfluencer = (influencer) => {
    setInfluencerData(influencer);
    setFormData({
      ...formData,
      influencerName: influencer.displayname,
      ER: influencer.ER,
      accessToken: influencer.longLivedAccess,
      instagramID: influencer.instaId,
      influencerID: influencer.influencerId,
    });

    // Set the details of the selected influencer (optional, for further use)
    setSelectedInfluencersDetails(influencer);

    // Clear the filtered list after selecting an influencer
    setFilteredInfluencers([]);
  };

  const handleInfluencerInput = (e) => {
    const input = e.target.value;
    setFormData({ ...formData, influencerName: input });

    if (input) {
      const filtered = creatorss.filter((influencer) =>
        influencer.displayname.toLowerCase().includes(input.toLowerCase())
      );

      // console.log(filtered);
      setFilteredInfluencers(filtered);
      // setSelectedInfluencersDetails(filtered[0])
    } else {
      setFilteredInfluencers([]);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setFormData({ ...formData, campaignLogo: file });
    }
  };

  const prepareFormDataForSubmission = () => {
    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "campaignLogo") {
        submissionData.append("campaignImg", formData[key]);
      } else {
        submissionData.append(key, formData[key]);
      }
    });
    return submissionData;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const id = brandID;
      const submissionData = prepareFormDataForSubmission();

      const response = await fetch(`${baseUrl}/campaign/createCampaign/${id}`, {
        method: "POST",
        body: submissionData,
      });

      if (response.ok) {
        setIsPopupVisible(true);
        handleSubmission(formData, influencerData, id);
      } else {
        alert("Campaign already running.");
      }
    } catch (error) {
      console.error("Error submitting campaign data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPopup = () => {
    const id = brandID;
    setIsPopupVisible(false);
    window.location.href = `/Brand/${id}`;
  };

  return (
    <div className={`${styles.createCampaignContainer}`}>
      {/* Popup Modal */}
      {isPopupVisible && (
        <>
          {/* Background fade overlay */}
          <div className="modal-backdrop fade show"></div>

          <div className="modal show d-block">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Campaign Created Successfully</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleNextPopup}
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Your campaign has been created successfully.</p>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-success" onClick={handleNextPopup}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="card p-4">
        <h2 className="text-center mb-4">Create Campaign</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="row w-100">
            {/* Campaign Details */}
            <div className="col-md-6">
              <div className="mb-3 position-relative">
                <label className="form-label">
                  Campaign Name:
                  <span
                    className="ms-2 text-info"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Enter a unique name for your campaign. This will help identify it later."
                  >
                    <FaInfoCircle style={{ color: "black" }} />
                  </span>
                </label>
                <input
                  type="text"
                  name="campaignName"
                  className="form-control"
                  value={formData.campaignName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Campaign Description</label>
                <textarea
                  name="campaignDescription"
                  className="form-control"
                  value={formData.campaignDescription}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="mb-3">
                <label className="form-label">Campaign Logo</label>
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Select an image, it is must for any campaign."
                >
                  <FaInfoCircle style={{ color: "black" }} />
                </span>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Campaign Goal</label>
                <select
                  name="campaignGoal"
                  className="form-select"
                  value={formData.campaignGoal}
                  onChange={handleGoalChange}
                  required
                >
                  <option value="">Select a goal</option>
                  <option value="Brand/product awareness">
                    Brand/product awareness
                  </option>
                  <option value="maximize app installs">
                    Maximize app installs
                  </option>
                  <option value="increase sales/conversions">
                    Increase sales/conversions
                  </option>
                  <option value="others">Others, specify</option>
                </select>
              </div>
              {formData.campaignGoal === "others" && (
                <div className="mb-3">
                  <label className="form-label">Specify Goal</label>
                  <input
                    type="text"
                    name="customGoal"
                    className="form-control"
                    value={formData.customGoal}
                    onChange={handleInputChange}
                  />
                </div>
              )}

              <div className="mb-3">
                <label className="form-label">Platform</label>
                <select
                  name="platform"
                  className="form-select"
                  value={formData.platform}
                  onChange={handleInputChange}
                  required
                >
                 
                  <option value="instagram">Instagram</option>
                  <option value="facebook">Facebook</option>
                  <option value="youtube">YouTube</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Deliverables</label>
                <select
                  name="deliverables"
                  className="form-select"
                  value={formData.deliverables}
                  onChange={handleInputChange}
                  required
                >
                  <option value="reel">Reel</option>
                  <option value="post">Post</option>
                 
                  <option value="story">Story</option>
                </select>
              </div>
            </div>

            {/* Platform and Influencers ///////////////////////////////////////////////////////////// */}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  className="form-control"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Enter a future end date for campaign. It will close at 1 pm on selected Date.."
                >
                  <FaInfoCircle style={{ color: "black" }} />
                </span>
                <input
                  type="date"
                  name="endDate"
                  className="form-control"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Total Budget:</label>
                <input
                  type="number"
                  name="totalBudget"
                  className="form-control"
                  value={formData.totalBudget}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Search Influencer:</label>
                <input
                  type="text"
                  name="influencerName"
                  className="form-control"
                  value={formData.influencerName}
                  onChange={handleInfluencerInput}
                  placeholder="Type influencer name"
                />
                {filteredInfluencers.length > 0 && (
                  <ul
                    className={`list-group mt-1 bg-light border-1 opacity-75 rounded ${styles.paraGap} overflow-auto`}
                    style={{ maxHeight: "200px" }}
                  >
                    {filteredInfluencers.map((influencer, index) => (
                      <li
                        key={index}
                        className="list-group-item"
                        onClick={() => handleSelectInfluencer(influencer)}
                      >
                        {influencer.displayname}
                        <span className="ms-3">{influencer.followers}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {selectedInfluencersDetails && (
                <div className={`alert alert-success p-2 ${styles.paraGap}`}>
                  <p>Name: {selectedInfluencersDetails.displayname}</p>
                  <p>Followers: {selectedInfluencersDetails.followers}</p>
                  <p>Engagement Rate: {selectedInfluencersDetails.ER}</p>
                </div>
              )}

              <div className="mb-3">
                <label className="form-label">Reel Link</label>
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Enter reel link. Please double cross-check that, reel is of the selected influencer."
                >
                  <FaInfoCircle style={{ color: "black" }} />
                </span>
                <input
                  type="text"
                  name="reelLink"
                  className="form-control"
                  value={formData.reelLink}
                  onChange={handleInputChange}
                  placeholder="Paste Reel Link"
                />

                <span className={styles.exampleSpan}>format- eg. https://www.instagram.com/reel/C-edjv45tkrv/</span>
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary mt-3"
            type="submit"
            disabled={loading}
          >
            {loading ? "Launching..." : "Launch Campaign"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCampaign;
