import React from "react";
import styles from "./CampaignColl.module.css";

const CampaignColl = ({ campaignData1 }) => {
  const campaigns = campaignData1 ?? [];

  return (
    <div className={styles.Collectioncontainer}>
      <div className={styles.cardContainer}>
        {campaigns.length === 0 ? (
          // Fallback UI when no campaign data is available
          <p className={styles.NoCampaignMessage}>No campaigns available</p>
        ) : (
          // Render campaign cards
          campaigns.map((campaign, index) => (
            <div key={index} className={styles.card}>
              <div>
                <img
                  src={campaign.campaignLogo || "https://via.placeholder.com/150"}
                  alt="CampaignThumbnail"
                />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.CampaignName}>{campaign.campaignName}</p>
                <p className={styles.CreatorCount}>{campaign.platform}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CampaignColl;
