import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./BrandProfile.module.css";
import { useDispatch, useSelector } from "react-redux";
import Overview from "./Overview/Overview";
import baseUrl from "../../api/universalApi";
import Requests from "./Requests/Requests";
import CampaignColl from "./CampaignCollection/CampaignColl";
import { useNavigate } from "react-router-dom";
import {saveCampaignData} from '../../actions/BrandActions'
import axios from "axios";


const BrandProfile = ({requestedCreator,campaignData,influencerData}) => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Fetching 'id' from the route parameters
 const navigate = useNavigate();
  const authData = useSelector((state) => state.auth.authData.user);
  const [campaignData1, setCampaignData] = useState(null);
  // collect all influencer Data.
  //get blogs
  const [activeTab, setActiveTab] = useState("overview");
  
  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        // Sending a simple GET request without JSON Content-Type
        const response = await axios.get(`${baseUrl}/campaign/getCampaignData/${id}`);
    
        if (response.status === 200) {
          const data = response.data.data; // Axios automatically parses JSON
          setCampaignData(data); // Storing fetched data in state
          //console.log("Fetched campaign data:", data);
          dispatch(saveCampaignData(data));
        } else {
          console.error("Failed to fetch campaign data");
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    if (id) {
      fetchCampaignData();
    }
  }, [id]); // Dependency array includes 'id' so it re-runs if 'id' changes

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview campaignData={campaignData} influencerData={influencerData}/>;
     
      case "requests":
        return <Requests requestedCreator={requestedCreator} />;
      case "collection":
        return <CampaignColl campaignData1={campaignData1} />;

      default:
        return <Overview />;
    }
  };

  const handleCampaign = ()=>{
    navigate("/createcampaign")
  }

  return (
    <>
      <div className={styles.dashboardContainer}>
        <header className={styles.dashboardHeader}>
          <h1>Welcome {authData.displayname},</h1>
          <div className={styles.dateDownload}>
          <button className={styles.btnColor} onClick={handleCampaign}>Create Campaign</button>
            <div className={styles.dateRange}>
              <span>13 June 2023 - 14 July 2023</span>
            </div>
            <button className={styles.downloadButton}>Download</button>
          </div>
        </header>
        <div className={styles.dashboardNav}>
          <div className={styles.buttonContainer}>
            <button
              className={`${styles.navItem} ${
                activeTab === "overview" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("overview")}
            >
              Overview
            </button>
           
            <button
              className={`${styles.navItem} ${
                activeTab === "requests" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("requests")}
            >
              Requests
            </button>
            <button
              className={`${styles.navItem} ${
                activeTab === "collection" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("collection")}
            >
              Collection
            </button>
          </div>
        </div>
        <div className={styles.contentContainer}>{renderContent()}</div>
      </div>
    </>
  );
};

export default BrandProfile;
